<template>
  <div>
    <div class="p10 font15 fontblod">建信服务会员</div>
    <div class="mt10" style="width: 100%;">
      <div v-if="!loading && finished">
        <div v-if="thail && thail.length > 0">
          <div class="ftleft mb10" v-for="(it,ke) in thail" :key="ke" @click="toPay(it,ke)">
            <div class="bors mcenter ml13" :class="[active == it.ProductId ? 'active' : 'bor1']">
              <p class="">{{it.ProductName}}</p>
              <div class="font26 mt3" :class="[active == it.ProductId ? 'colda' : '']">
                <span class="font14">￥</span>{{istiyan && istiyan == "1"?it.FirstTimePrice:it.PromotPrice}}
                <div class="col7f font13 bortop">￥<span class="font15">{{it.Price}}</span></div>
              </div>
              <div class="font13 colred paddzy5 mt5" >{{it.ProductInfo?it.ProductInfo: ""}}</div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
        <div v-else>暂无数据</div>
        <div class="padd3 bgf6"></div>
        <div v-if="active" class="">
          <div class="font14 fontblod padd10 ml10">会员权益</div>
          <van-image :src="require('@/assets/vipPower.png')" width="100vw" fit="widthFix"></van-image>
<!--          <div>
            <van-grid column-num="3">
              <van-grid-item icon="photo-o" use-slot :clickable="true" :text="it.name" v-for="(it,ke) in funcInfo" :key="ke">
                <div class="mcenter" style="height: 70px;">
                  <van-icon :name="require('@/assets/dingyue.png')" :size="34"/>
                  <div class="mt7 font14">{{it.name}}</div>
                </div>
              </van-grid-item>
            </van-grid>
          </div>-->
        </div>
        <div class="padd30 mt30"></div>
      </div>
      <div v-else>
        <van-loading size="30" color="#1890ff" vertical><span class="mt10 colchose">加载中...</span></van-loading>
      </div>
      <div class="padd20"></div>
    </div>
    <div class="padd20"></div>

      <div style="position: fixed;bottom: 50px;width: 100vw">
        <div v-if="activeType.type == 'DONATE'" class="bgwhite" style="border-top: 1px solid #ddd;">
          <div class="font15 colPrice mt7 ml10 fontblod">{{activeType.name}}</div>
          <div class="mt10 paddzy15">
            <div style="border-top: 1px solid #f2f2f2;"></div>
          </div>
          <div>
            <van-field v-model.number="donatePrice" placeholder="请输入打赏金额" @input="priceChange" type="number" clearable/>
          </div>
          <div style="border-top: 1px solid #f2f2f2"></div>
          <div class="padd5"></div>
        </div>
        <div v-if="discount && discount.length > 0">
          <div class="" style="border-top: 1px solid #ddd;"></div>
          <div class="paddzy10 bgwhite">
            <div class="font15 fontblod padd10 ml10 colPrice">年限</div>
            <div class="padd10">
              <div v-for="(it,ke) in discount" :key="ke" class=" ftleft mr10" style="position: relative;" @click="toPay('year',it)">
                <div style="position: absolute;right: 0px;top: -20px"><van-tag plain type="danger" round size="medium" class="fontblod font13">{{it.discount}}折</van-tag></div>
                <!-- <span style="position: absolute;top: -15px;border: 1px solid darkorange;background-color: ;border-radius: 15%;padding: 2px 5px;">{{it.discount}}</span> -->
                <span class="bor1 font14 bgwhite  " :class="[yearActive.num == it.num?'active1':'bor1']" style="padding: 12px 20px;"><span class="fontblod font20">{{it.num}}</span>{{year=='s'?"季度":(year=='m'?"个月":"年")}}</span>
              </div>
              <div class="clear"></div>
            </div>
            <div class="padd8 bgwhite"></div>
            <div style="border-top: 1px solid #f2f2f2"></div>
          </div>
        </div>
      </div>
      <van-submit-bar
          :price="price"
          :button-text="niche ? '支付' : '提交订单'"
          @submit="onSubmit"
          bar-class="colda"
      />
      <div v-if="pctype == false"></div>
    <div v-else class="mcenter colred">请在手机微信上打开并操作</div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import wx from "weixin-js-sdk";
import {Toast} from "vant";
import {toSubscribe} from "@/utils/utils";

export default {
  name: "jxfwVip",
  data(){
    return{
      price: 0,
      phoneNum: {},
      thail: [],
      active: "",
      funcInfo: [],
      islate: false,
      imgurl: "",
      niche: false,
      types: "",
      tokens: {
        token: ""
      },
      code: "",
      url: "",
      loading: false,
      finished: false,
      pctype: false,
      state: "",
      from: "",
      istiyan: "",
      year: 1,
      discount: [],
      yearActive: {},
      activeType: {
        name: "",
        type: "",
      },
      donatePrice: null,
      payLoading: false,
    }
  },
  created() {
    this.initData();
    this.getCode();
    this.pctype = false;
    //let ua = window.navigator.userAgent.toLowerCase();
    let plat = navigator.platform;
    //alert("1--"+plat);
    let win = plat.indexOf("Win") == 0;
    let mac = plat.indexOf("Mac") == 0;
    if (win || mac) {
      this.pctype = true
    }
  },
  mounted() {
    this.getPrice()
    //this.login()
  },
  methods:{
    getCode(){
      /*let href = "https://sixu.work/jxpay?code=0215mrll2y2Q384NSPll2yyy9q05mrlB&state=70aec52f26e3451c89e609ff6c80307c&from=lidongy&isTiYan=1"
      if (href.indexOf("state") != -1){

        this.code= id.code;
        this.state = id.state;
        console.log("state",qs.parse(window.location.href.split("#")[0].split("?")[1]));
      }*/
      //this.url = window.location.href
      if (window.location.href.indexOf("code") != -1){
        let id = qs.parse(window.location.href.split("#")[0].split("?")[1])
        this.code = id.code;
        this.state = id.state;
        //Toast("code--",this.code);
        this.from = id.from;
        this.istiyan = id.isTiYan
      }
      //this.code = "0d3qyN000Gz0KP19q4100qhFrY3qyN02";
    },
    initData(){
      this.price = 0;
      this.phoneNum = {};
      this.active = "";
      this.funcInfo = [];
      this.niche = false;
      this.types = "";
      this.tokens = {
        token: ""
      }
      this.code = "";
      this.url = "";
      this.loading = false;
      this.finished = false;
      this.state = "";
      this.from = "";
      this.istiyan = "";
      this.year = 1;
      this.discount = [];
      this.yearActive = {};
      this.activeType =  {
        name: "",
        type: "",
      };
      this.donatePrice = null;
      this.payLoading = false;
    },
    priceChange(e){
      this.price = 0;
      this.price = this.donatePrice * 100
    },
    onSubmit(){
      if (this.price > 0){
        this.payLoading = true;
        this.getOrders();
      }else {
        Toast("请选择项目类型")
      }
    },
    toPay(it,ke){
      console.log("1222",it)
      if (this.istiyan && this.istiyan == "1"){
        this.price = it.FirstTimePrice*100;
      }else if(it == "year"){
        this.price = ke.price * 100;
        this.yearActive = ke;
        /*this.yearActive = {
          discount: "",
          num: null,
          price: null,
        }
        this.$set(this.yearActive,"discount",ke.discount);
        this.$set(this.yearActive,"num",ke.num);
        this.$set(this.yearActive,"price",ke.price);*/
        console.log("yearActive",this.yearActive)
      }else {
        this.active = it.ProductId;
        this.funcInfo = it.infoDesc;
        this.$set(this.activeType,"name",it.ProductName);
        this.$set(this.activeType,"type",it.ProductType)
        this.discount = [];
        this.year = "";
        this.yearActive = {};
        if (it.ProductId != 'DONATE_A'){
          this.price = it.PromotPrice*100;
        }else {
          this.price = 0;
        }
        if (it.Discount){
          if (it.ProductId.indexOf("M-")!=-1){
            this.year = "m";
          }
          if (it.ProductId.indexOf("S-")!=-1){
            this.year = "s";
          }
          if (it.ProductId.indexOf("Y-")!=-1){
            this.year = "y";
          }
          this.discount = it.Discount;
          this.yearActive = this.discount[0];
        }
      }
    },
    getOrders(){
      let data = {
        payType: this.active,
        code: this.code,//"031OutFa1Lrs0G0qivGa19aNUT3OutFt"
        payMode:"wxmp",
        state: this.state,
        from: this.from,
        isTiYan: this.istiyan,
      }
      //console.log("yearActive",this.yearActive,this.active)
      if (this.yearActive && this.yearActive.num && this.active != "S-SVIP"){
        data.num = this.yearActive.num;
        data.price = this.yearActive.price;
      }
      if (this.activeType.type == "DONATE"){
        data.num = this.donatePrice
      }
      axios.post("https://jz.4lambs.com:443/jxfw/pay/getOrder",data).then(res => {
        if (res != null && res.data != null && res.data.content != null){
          let id = res.data.content;
          if (typeof window.WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              setTimeout(() => {
                document.addEventListener('WeixinJSBridgeReady', toSubscribe(id), false);
              },1000)
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', toSubscribe(id));
              document.attachEvent('onWeixinJSBridgeReady', toSubscribe(id));
            }
          } else {
            toSubscribe(id);
          }
        }else {
          Toast(res.data.errorMsg)
        }
      })
    },
    getPrice(){
      this.loading = true;
      this.finished = false;
      this.thail = [];//beta
      axios.get("https://jz.4lambs.com:443/jxfw/pay/product").then(res => {
        //console.log("res--price",res);
        if (res != null && res.data != null && res.data.content != null){
          this.thail = res.data.content;
          let list = [];
          let index = null;
          if (this.thail && this.thail.length > 0){
            for (let i in this.thail){
              let it = this.thail[i];
              if (it.ProductFunc){
                let info = JSON.parse(it.ProductFunc);
                it.infoDesc = info;
                //console.log("info",info)
              }
              if (it.Discount){
                it.Discount = JSON.parse(it.Discount)
              }
              if (it.ProductId == "SJ-VIP"){
                list.push(it)
              }
              if (it.ProductId == this.types){
                index = i;
              }
            }
            if (this.niche){
              this.thail = list;
            }
          }
          if (this.types && index != null){
            this.price = this.thail[index].PromotPrice * 100;
            this.active = this.thail[index].ProductId;
            this.funcInfo = this.thail[index].infoDesc;
            this.discount = this.thail[index].Discount;
            if (this.discount && this.discount.length > 0){
              this.yearActive = this.discount[0];
            }
          }else {
            this.price = this.thail[0].PromotPrice * 100;
            this.active = this.thail[0].ProductId;
            this.funcInfo = this.thail[0].infoDesc;
            if (this.thail[0] && this.thail[0].Discount){
              this.discount = this.thail[0].Discount;
              if (this.discount && this.discount.length > 0){
                this.yearActive = this.discount[0];
              }
            }
          }
          if (this.thail && this.thail.length > 0){
            this.loading = false;
            this.finished = true;
          }
        }else {
          Toast(res.data.errorMsg);
          this.loading = false;
          this.finished = true;
        }
      })
    },
    login(){
      let _this = this;
      wx.login({
        success: function (res){
          if (res.code){
            _this.code = res.code
            let param = {
              code: res.code,
            }
            axios.post("https://jz.4lambs.com:443/jxfw/sys/login",param).then(res => {
              if (res && res.data && res.data.content){
                _this.tokens.token = res.data.content.token;
                //setStorage("jxfw_token",JSON.stringify(res.data.content))
              }
            })
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.bors{padding: 12px 0 10px 0;border-radius: 5px;width: 28vw;height: 115px;}
.active{border: darkorange solid 1px;}
.bortop{text-decoration: line-through;}
.blackg{background: #333333;height: 180px;width: 100vw; padding-top: 5px;}
.gloadg{background: linear-gradient(to right,rgb(253,246,214),rgb(247,200,114));width: 90vw;margin: 0 auto;
  padding: 10px 0;border-radius: 20px;}
.bortop{text-decoration: line-through;}
.bor1{border: #f2f2f2 solid 2px;}
.colPrice{color: rgb(214,166,82);}
.active1{border: rgb(214,166,82) solid 2px;background: rgba(214,166,82,0.1);color: rgb(214,166,82);}

.vips .van-grid-item__content{padding: 10px 0 0px 0}

</style>